<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon @click="$router.go(-1)" class="h-7 w-7 pt-2 cursor-pointer" />
          <h2 class="font-semibold">Banner Upload</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <DragDropImage @changed="handleImage" :current-image="currentBanners" :max="1" class="text-black"
          clearAll="Clear All" maxError="Maximum one file only" />
        <br />
        <button @click="saveBanner()" class="btn bg-green-500 w-full border-none">
          Save
        </button>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import { useRouter } from "vue-router";

import SideNavBar from '../components/SideNavBar.vue';
import DragDropImage from '../components/DragDropImage.vue';

import { ChevronLeftIcon } from "@heroicons/vue/outline";

const $router = useRouter();

const $swal = inject("vm").config.globalProperties.$swal;
const currentBanners = ref([]);
const $axios = inject("axios");
const isLoading = ref(false);
const newBanner = ref([]);

const getBanners = async () => {
  isLoading.value = true;
  try {
    const { data } = await $axios.get("/subpage-banner");

    currentBanners.value = data;
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    throw error;
  }
}

const handleImage = (images) => {
  currentBanners.value = [];
  newBanner.value = images;
}

const saveBanner = async () => {
  isLoading.value = true;
  try {
    if (newBanner.value.length > 0 || currentBanners.value.length === 0) {
      const form = new FormData();
      for (const x of newBanner.value) {
        form.append("file", x);
      }

      const { data } = await $axios.post("/subpage-banner", form);

      currentBanners.value = data;
      newBanner.value = [];
    }

    isLoading.value = false;
    $swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      icon: "success",
      title: "Banner updated",
    });
  } catch (error) {
    isLoading.value = false;
    $swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      icon: "error",
      title: "Unexpected error occured. Please try agian later.",
    });
    throw error;
  }
}

onMounted(() => {
  getBanners();
});
</script>

<style></style>